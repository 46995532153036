import DOMPurify from 'isomorphic-dompurify';
import type { FC } from 'react';
import Head from 'next/head';
import { useConfig } from '@packages/utilities';
import { usePathname } from 'next/navigation';
import { useI18n } from '../../hooks/useI18n';
import { useUrl } from '../../hooks/useUrl';

export type HeadMetaProps = {
  /** meta data for <meta /> tags. */
  meta?: {
    title?: string;
    description?: string;
    keywords?: string;
    robots?: string;
  };
  /** links for <link /> tags. */
  links?: {
    alternateLinks?: {
      [x: string]: string;
    } | null;
    canonical?: string;
  };
  /** global schema.org data to be rendered in <script> tag. */
  schemaorg?:
    | string
    | {
        [x: string]: string | { [k: string]: string } | undefined;
      }[];
  /** meta data for <meta /> tags used in socialmedia. */
  socialMeta?: {
    image?: string;
    description?: string;
    title?: string;
    type?: string;
    price?: string;
    currency?: string;
  };
};

/**
 * HeadMeta component renders title, meta, links and global schema.org data in the document head.
 * */
export const HeadMeta: FC<HeadMetaProps> = ({ meta, links, schemaorg, socialMeta }) => {
  const pathname = usePathname();
  const asPath = useUrl();
  const { language } = useI18n();
  const { company } = useConfig();
  const { title, description, keywords, robots } = meta || {};
  const {
    type: socialType,
    title: socialTitle,
    description: socialDescription,
    image: socialImage,
    price: socialPrice,
    currency: socialCurrency,
  } = socialMeta || {};
  const {
    alternateLinks,
    canonical = typeof window !== 'undefined'
      ? `${window.location.origin}${window.location.pathname}`
      : pathname,
  } = links || {};

  return (
    <Head>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {robots && <meta name="robots" content={robots} />}
      {alternateLinks &&
        Object.keys(alternateLinks).map((alternateLanguage) =>
          alternateLanguage !== language ? (
            <link
              key={alternateLanguage}
              rel="alternate"
              hrefLang={alternateLanguage}
              href={alternateLinks[alternateLanguage]}
            />
          ) : null,
        )}
      {canonical && <link href={canonical} rel="canonical" />}
      {schemaorg &&
        (typeof schemaorg === 'string' ? (
          <script
            type="application/ld+json"
            key="schemaorg"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(schemaorg) }}
          />
        ) : (
          schemaorg.map((schemaJSON, index) => (
            <script
              type="application/ld+json"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(JSON.stringify(schemaJSON)) }}
            />
          ))
        ))}
      <meta name="twitter:card" content="summary_large_image" />
      {socialType && <meta property="og:type" content={socialType} />}
      {socialTitle && <meta property="og:title" content={socialTitle} />}
      {socialDescription && <meta property="og:description" content={socialDescription} />}
      <meta property="og:url" content={asPath} />
      <meta property="og:site_name" content={company.name} />
      {socialPrice && <meta property="product:price:amount" content={socialPrice} />}
      {socialCurrency && <meta property="product:price:currency" content={socialCurrency} />}
      {socialImage && <meta property="og:image" content={socialImage} />}
    </Head>
  );
};
